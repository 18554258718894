import { Routes } from '@angular/router';
import { i18nGuard } from '@pixels/client/guards/i18n.guard';
import { IChatParamKey } from '@pixels/universal/model/insta-chat/insta-chat-constant';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/home/home.component').then(m => m.HomeComponent),
  },
  {
    path: `static/:${IChatParamKey.contentName}`,
    canActivate: [i18nGuard],
    data: { url: 'https://imsolochat-api.pixelteam.io/service-contents' },
    loadComponent: () => import('@pixels/client/pages/static-viewer/static-viewer.page').then(m => m.StaticViewerPage),
  },

  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  },
];
