import { Component, Injector } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { AppInjector } from '@pixels/client/app-injector/app.injector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet
  ]
})
export class AppComponent {
  constructor(injector: Injector) {
    AppInjector.getInstance().setInjector(injector);
  }
}
