import { InjectionToken, ModuleWithProviders, Type } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Lang, LANGUAGE_CODES } from '@pixels/universal/model/i18n/i18n.model';
import { isEqual } from 'lodash-es';

export function getLanguageCode(code: string, languageCodes: Lang[]): Lang | undefined {
  return languageCodes.find(languageCode => isEqual(code, languageCode));
}

export function createTranslateModuleForRoot(useClass: Type<TranslateLoader>, defaultLanguage: Lang = Lang.KO): ModuleWithProviders<TranslateModule> {
  return TranslateModule.forRoot({
    loader: { provide: TranslateLoader, useClass },
    defaultLanguage,
    useDefaultLang: true
  });
}

export const SUPPORT_LANGUAGE_CODES_TOKEN = new InjectionToken<Lang[]>('SUPPORT_LANGUAGE_CODES_TOKEN', {
  providedIn: 'root',
  factory: (): Lang[] => LANGUAGE_CODES
});

export const LANGUAGE_BY_BROWSER_TOKEN = new InjectionToken<boolean>('LANGUAGE_BY_BROWSER_TOKEN', {
  providedIn: 'root',
  factory: (): boolean => false
});

export const DEFAULT_LANGUAGE_TOKEN = new InjectionToken<Lang>('DEFAULT_LANGUAGE_TOKEN', {
  providedIn: 'root',
  factory: (): Lang => Lang.KO
});
