import { Injectable, signal } from '@angular/core';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyPixelteam } from '@pixels/universal/model/apps/app-types';

@Injectable({ providedIn: 'root' })
export class PixelteamAppInfo extends AppInfo {
  public readonly appType = AppKeyPixelteam;
  public readonly serviceName = 'PIXELTEAM';
  public readonly appStoreUrl = signal('');
  public readonly playStoreUrl = signal('');
}
