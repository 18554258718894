import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SUPPORT_LANGUAGE_CODES_TOKEN } from '@pixels/client/i18n-util/i18n-util';
import { initLogger, LoggerService } from '@pixels/client/logger/logger.service';
import { AppStateService, migrateForage } from '@pixels/client/ngforage/app-state.service';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { Lang } from '@pixels/universal/model/i18n/i18n.model';
import { QuicklinkModule } from 'ngx-quicklink';
import { AppI18nLoader } from '../../../chat-korean-client/src/app/app-i18n-loader';

import { routes } from './app.routes';
import { PixelteamAppInfo } from './pixelteam-app-info';

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular(),
    provideHttpClient(withInterceptors([])),
    provideRouter(routes),
    { provide: AppInfo, useClass: PixelteamAppInfo },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, useFactory: migrateForage, deps: [AppStateService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initLogger, deps: [LoggerService], multi: true },
    importProvidersFrom(
      QuicklinkModule,
      TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: AppI18nLoader } }),
    ),
    { provide: SUPPORT_LANGUAGE_CODES_TOKEN, useValue: [Lang.KO, Lang.EN] },
  ]
};
